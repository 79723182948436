import React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const Error404 = ({ data }) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>404 Not Found - {data.site.siteMetadata.title}</title>
            </Helmet>
            <Layout>
                <article>
                    <h1 className="title is-1">Táto stránka neexistuje 😢</h1>
                    <p>
                        Na tejto adrese nič nie je.
                    </p>
                </article>
            </Layout>
        </React.Fragment>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;

export default Error404;
